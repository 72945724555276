export function copyMapAndDelete<
  K extends PropertyKey,
  M extends Map<K, unknown>
>(map: M, key: K): M {
  const nMap = new Map(map) as M;
  nMap.delete(key);
  return nMap;
}

export function copyMapAndReplace<
  M extends Map<PropertyKey, unknown>,
  Key extends M extends Map<infer K, unknown> ? K : never,
  Value extends M extends Map<PropertyKey, infer V> ? V : never
>(map: M, key: Key, value: ((prev?: Value) => Value) | Value): Map<Key, Value> {
  return new Map(map).set(
    key,
    typeof value === "function"
      ? (value as (prev: Value) => Value)(map.get(key) as Value)
      : value,
  ) as Map<Key, Value>;
}
