import React from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import useAuth from "../services/auth/hooks/useAuth";
import Public from "../views/public/Public";
import { ERROR_ACCESS_NOT_AUTHORIZED, LOGOUT, PUBLIC } from "./public";
import Private from "../views/private/Private";
import { PRIVATE } from "./private";
import useLoader from "../services/routing/useLoader";
import Loading from "../services/routing/components/Loading";
import LoaderErrors from "../services/routing/components/LoaderErrors";
import AccountNotAuthorized from "src/views/AccountNotAuthorized";

export const HOME = "/";

const Logout = (props: RouteComponentProps): JSX.Element => {
  const { logout } = useAuth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { loading, error, reload } = useLoader(logout, []);

  if (loading) return <Loading />;
  if (error) return <LoaderErrors reload={reload} error={error} />;

  return <Redirect to={PUBLIC} />;
};

const Routes: React.FC = () => {
  const { user } = useAuth();

  return (
    <Router>
      <Redirect from={HOME} to={PUBLIC} noThrow default />
      <Public path={PUBLIC} />
      <AccountNotAuthorized path={PUBLIC + ERROR_ACCESS_NOT_AUTHORIZED} />
      <Logout path={LOGOUT} />

      {user !== null && <Private path={`${PRIVATE}/*`} />}
    </Router>
  );
};

export default Routes;
