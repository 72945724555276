import { RouteComponentProps } from "@reach/router";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDashboard,
  withProvideDashboard,
} from "src/services/dashboard/dashboardProvider";
import LoaderErrors from "src/services/routing/components/LoaderErrors";
import Loading from "src/services/routing/components/Loading";
import useLoader from "src/services/routing/useLoader";
import { addMonths, formatISO, isValid, parseISO } from "date-fns";
import { Doughnut, PolarArea, Bar } from "react-chartjs-2";
import { EnvColor, Environment } from "src/services/dashboard/dashboard";
import {
  CONTRIBUTION_STATUSES_ENTRIES,
  CONTRIBUTION_TYPES_ENTRIES,
  ContributionStatus,
  ContributionType,
} from "src/services/contributions/contribution";
import { copyMapAndReplace } from "src/services/data-structures/map";
import {
  COMMITTEE_TYPES_ENTRIES,
  CommitteeType,
} from "src/services/committees/committee";
import useQueryParams from "src/services/routing/useQueryParams";
import { useDeepEffect } from "src/services/data-structures/any";
import { MultiSelect, Option } from "react-multi-select-component";
import { cx } from "@emotion/css";

function ifInclude<T, V>(arr: T[], el: T, val: V) {
  return arr.includes(el) ? [val] : [];
}

const Y = "y";
const RIGHT = "right";
const BOLD = "bold";
const END = "end";

const DashboardBody = ({
  environments,
  fromDate,
  toDate,
}: {
  environments: Environment[];
  fromDate: Date;
  toDate: Date;
}): JSX.Element => {
  const { t } = useTranslation([
    "dashboard",
    "contributions",
    "committees",
    "users",
  ]);
  const { loadDashboard, dashboard } = useDashboard();

  const { loading, error, reload } = useLoader(
    () =>
      environments.length > 0
        ? loadDashboard(environments, fromDate, toDate)
        : Promise.resolve(),
    [fromDate, toDate, environments], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const totalAccountsCount = useMemo(
    () =>
      (dashboard?.france?.allUsersCount || 0) +
      (dashboard?.italy?.allUsersCount || 0) +
      (dashboard?.plant?.allUsersCount || 0) +
      (dashboard?.spain?.allUsersCount || 0),
    [dashboard?.france, dashboard?.italy, dashboard?.plant, dashboard?.spain],
  );
  const internalAccountsCount = useMemo(
    () =>
      (dashboard?.france?.internalUsersCount || 0) +
      (dashboard?.italy?.internalUsersCount || 0) +
      (dashboard?.plant?.internalUsersCount || 0) +
      (dashboard?.spain?.internalUsersCount || 0),
    [dashboard?.france, dashboard?.italy, dashboard?.plant, dashboard?.spain],
  );
  const externalAccountsCount = useMemo(
    () =>
      (dashboard?.france?.externalUsersCount || 0) +
      (dashboard?.italy?.externalUsersCount || 0) +
      (dashboard?.plant?.externalUsersCount || 0) +
      (dashboard?.spain?.externalUsersCount || 0),
    [dashboard?.france, dashboard?.italy, dashboard?.plant, dashboard?.spain],
  );

  const ideasPerTypology = useMemo(() => {
    const allEnvPerType = dashboard
      ? [
          ...(dashboard.france ? dashboard.france.countByType : []),
          ...(dashboard.spain ? dashboard.spain.countByType : []),
          ...(dashboard.italy ? dashboard.italy.countByType : []),
          ...(dashboard.plant ? dashboard.plant.countByType : []),
        ].reduce(
          (acc, count) =>
            copyMapAndReplace(
              acc,
              [
                ContributionType.PRODUCTS,
                ContributionType.SYSTEM,
                ContributionType.ACCESSORY,
                ContributionType.PACKAGING,
                ContributionType.SERVICE,
              ].includes(count.type)
                ? count.type
                : ContributionType.OTHER,
              (c) => (c || 0) + count.count,
            ),
          new Map<ContributionType, number>(),
        )
      : new Map();

    return [...allEnvPerType.entries()]
      .sort((a1, a2) => {
        const order = [
          ContributionType.PRODUCTS,
          ContributionType.SERVICE,
          ContributionType.SYSTEM,
          ContributionType.ACCESSORY,
          ContributionType.PACKAGING,
          ContributionType.OTHER,
        ];
        return (
          order.findIndex((v) => v === a1[0]) -
          order.findIndex((v) => v === a2[0])
        );
      })
      .map(
        (x) =>
          [
            CONTRIBUTION_TYPES_ENTRIES.find((e) => Number(e[0]) === x[0])![1],
            x[1],
          ] as const,
      ) as [keyof typeof ContributionType, number][];
  }, [dashboard]);

  const [topIdeasInternal, setTopIdeasInternal] = useState(true);
  const topIdeas = useMemo(() => {
    const allEnv = (dashboard
      ? [
          ...(dashboard.france ? dashboard.france.topContributions : []),
          ...(dashboard.spain ? dashboard.spain.topContributions : []),
          ...(dashboard.italy ? dashboard.italy.topContributions : []),
          ...(dashboard.plant ? dashboard.plant.topContributions : []),
        ]
      : []
    ).filter(
      (c) =>
        (c.internal && topIdeasInternal) || (!c.internal && !topIdeasInternal),
    );

    return allEnv
      .sort((c1, c2) => {
        if (c1.totalCompanyScore !== 0 && c2.totalCompanyScore !== 0)
          return c2.totalCompanyScore - c1.totalCompanyScore;
        if (c1.totalCompanyScore !== 0 && c2.totalCompanyScore === 0) return -1;
        if (c2.totalCompanyScore !== 0 && c1.totalCompanyScore === 0) return 1;

        if (c1.totalCustomerScore !== 0 && c2.totalCustomerScore !== 0)
          return c2.totalCustomerScore - c1.totalCustomerScore;
        if (c1.totalCustomerScore !== 0 && c2.totalCustomerScore === 0)
          return -1;
        if (c2.totalCustomerScore !== 0 && c1.totalCustomerScore === 0)
          return 1;
        return 0;
      })
      .slice(0, 20);
  }, [dashboard, topIdeasInternal]);

  const ideasPerStatus = useMemo(() => {
    const allEnv = (dashboard
      ? [
          ...(dashboard.france
            ? Object.entries(dashboard.france.countByStatus)
            : []),
          ...(dashboard.spain
            ? Object.entries(dashboard.spain.countByStatus)
            : []),
          ...(dashboard.italy
            ? Object.entries(dashboard.italy.countByStatus)
            : []),
          ...(dashboard.plant
            ? Object.entries(dashboard.plant.countByStatus)
            : []),
        ]
      : []
    ).reduce(
      (acc, [status, count]) => {
        return ([
          ContributionStatus.SUBMITTED,
          ContributionStatus.FIRST_EVALUATION,
          ContributionStatus.SECOND_EVALUATION,
          ContributionStatus.RETAINED,
          ContributionStatus.REFUSED,
        ] as (ContributionStatus | null)[]).includes(Number(status))
          ? copyMapAndReplace(acc, Number(status), (c) => (c || 0) + count)
          : acc;
      },

      new Map<ContributionStatus, number>(),
    );

    return [...allEnv.entries()]
      .sort((a, b) => a[0] - b[0])
      .map(
        ([k, v], index) =>
          [
            t(
              `dashboard:contribution-status.${
                CONTRIBUTION_STATUSES_ENTRIES.find(
                  (e) => Number(e[0]) === k,
                )![1]
              }`,
            ),
            v,
            /* eslint-disable i18next/no-literal-string */
            k === ContributionStatus.RETAINED
              ? "rgb(117, 181, 70)"
              : k === ContributionStatus.FIRST_EVALUATION
              ? "rgb(97, 198, 220)"
              : k === ContributionStatus.SECOND_EVALUATION
              ? "rgb(167, 216, 233)"
              : k === ContributionStatus.REFUSED
              ? "rgb(247, 167, 0)"
              : ContributionStatus.SUBMITTED
              ? "rgb(239, 209, 125)"
              : "rgb(0, 0, 0)",
            /* eslint-enable i18next/no-literal-string */
          ] as const,
      );
  }, [dashboard, t]);

  const ideasPerCommittee = useMemo(() => {
    const allEnv = (dashboard
      ? [
          ...(dashboard.france
            ? [
                [
                  CommitteeType.COMMITTEE_1,
                  dashboard.france.averageCustomerScore,
                ],
                [
                  CommitteeType.COMMITTEE_2,
                  dashboard.france.averageCompanyScore,
                ],
              ]
            : []),
          ...(dashboard.spain
            ? [
                [
                  CommitteeType.COMMITTEE_1,
                  dashboard.spain.averageCustomerScore,
                ],
                [
                  CommitteeType.COMMITTEE_2,
                  dashboard.spain.averageCompanyScore,
                ],
              ]
            : []),
          ...(dashboard.italy
            ? [
                [
                  CommitteeType.COMMITTEE_1,
                  dashboard.italy.averageCustomerScore,
                ],
                [
                  CommitteeType.COMMITTEE_2,
                  dashboard.italy.averageCompanyScore,
                ],
              ]
            : []),
          ...(dashboard.plant
            ? [
                [
                  CommitteeType.COMMITTEE_1,
                  dashboard.plant.averageCustomerScore,
                ],
                [
                  CommitteeType.COMMITTEE_2,
                  dashboard.plant.averageCompanyScore,
                ],
              ]
            : []),
        ]
      : []
    ).reduce(
      (acc, [status, count]) => {
        return copyMapAndReplace(acc, Number(status), (c) => (c || 0) + count);
      },

      new Map<CommitteeType, number>(),
    );

    return [...allEnv.entries()]
      .sort((a, b) => a[0] - b[0])
      .map(
        ([k, v]) =>
          [
            t(
              `committees:type.${
                COMMITTEE_TYPES_ENTRIES.find((e) => Number(e[0]) === k)![1]
              }`,
            ),
            v,
          ] as const,
      );
  }, [dashboard, t]);

  if (loading) return <Loading />;
  if (error) return <LoaderErrors reload={reload} error={error} />;

  return (
    <div className="grid">
      <div className="col-md-3-5">
        <div className="card dashboard-block">
          <div className="card-head">
            <h2>{t("dashboard:TOTAL_CONTRIBUTIONS")}</h2>
          </div>
          <div className="card-body">
            <PolarArea
              data={{
                labels: [
                  ...ifInclude(
                    environments,
                    Environment.France,
                    `France ${dashboard!.france?.contributionsCount}`,
                  ),
                  ...ifInclude(
                    environments,
                    Environment.Spain,
                    `Spain ${dashboard!.spain?.contributionsCount}`,
                  ),
                  ...ifInclude(
                    environments,
                    Environment.Italy,
                    `Italy ${dashboard!.italy?.contributionsCount}`,
                  ),
                  ...ifInclude(
                    environments,
                    Environment.Plant,
                    `Plant ${dashboard!.plant?.contributionsCount}`,
                  ),
                ],
                datasets: [
                  {
                    backgroundColor: [
                      ...ifInclude(
                        environments,
                        Environment.France,
                        EnvColor[Environment.France],
                      ),
                      ...ifInclude(
                        environments,
                        Environment.Spain,
                        EnvColor[Environment.Spain],
                      ),
                      ...ifInclude(
                        environments,
                        Environment.Italy,
                        EnvColor[Environment.Italy],
                      ),
                      ...ifInclude(
                        environments,
                        Environment.Plant,
                        EnvColor[Environment.Plant],
                      ),
                    ],
                    data: [
                      ...ifInclude(
                        environments,
                        Environment.France,
                        dashboard!.france?.contributionsCount,
                      ),
                      ...ifInclude(
                        environments,
                        Environment.Spain,
                        dashboard!.spain?.contributionsCount,
                      ),
                      ...ifInclude(
                        environments,
                        Environment.Italy,
                        dashboard!.italy?.contributionsCount,
                      ),
                      ...ifInclude(
                        environments,
                        Environment.Plant,
                        dashboard!.plant?.contributionsCount,
                      ),
                    ],
                  },
                ],
              }}
              options={{
                scales: {
                  r: {
                    ticks: {
                      display: false,
                      color: "#000",
                      font: {
                        size: 16,
                      },
                    },
                  },
                },
                plugins: {
                  legend: {
                    position: RIGHT,
                    labels: {
                      font: {
                        weight: BOLD,
                      },
                    },
                  },
                },
              }}
            />
          </div>
        </div>
        <div className="card dashboard-block">
          <div className="card-head">
            <h2>{t("dashboard:CONTRIBUTIONS_PER_STATUS")}</h2>
          </div>
          <div className="card-body">
            {ideasPerStatus.length > 0 ? (
              <div
                className="chart-container"
                style={{
                  position: "relative",
                  height: `${ideasPerStatus.length * 60}px`,
                }}
              >
                <Bar
                  data={{
                    labels: ideasPerStatus.map(([, v]) => v),
                    datasets: ideasPerStatus.map(([k, v, color], idx) => ({
                      label: k,
                      data: [...Array(ideasPerStatus.length)].map((_, i) =>
                        i === idx ? v : null,
                      ),
                      backgroundColor: color,
                    })),
                  }}
                  options={
                    {
                      indexAxis: Y,
                      grouped: false,
                      barPercentage: "0.75",
                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          beginAtZero: true,
                          ticks: {
                            font: {
                              weight: BOLD,
                            },
                          },
                        },
                        x: {
                          min: 1,
                          ticks: {
                            precision: 0,
                          },
                        },
                      },
                      plugins: {
                        legend: {
                          align: END,
                        },
                      },
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } as any
                  }
                />
              </div>
            ) : (
              t("dashboard:NO_DATA")
            )}
          </div>
        </div>
        <div className="card dashboard-block">
          <div className="card-head">
            <h2>{t("dashboard:AVERAGE_SCORE_PER_COMMITTEE")}</h2>
          </div>
          <div className="card-body">
            {ideasPerCommittee.length > 0 ? (
              <div
                className="chart-container"
                style={{ position: "relative", height: "175px" }}
              >
                <Bar
                  data={{
                    labels: ideasPerCommittee.map(([, v]) => v.toFixed(2)),
                    datasets: ideasPerCommittee.map(([k, v], idx) => ({
                      label: k,
                      data: [...Array(ideasPerCommittee.length)].map((_, i) =>
                        i === idx ? v : null,
                      ),
                      backgroundColor: [
                        "rgb(117, 181, 70)",
                        "rgb(247, 167, 0)",
                      ][idx],
                    })),
                  }}
                  options={
                    {
                      indexAxis: Y,
                      grouped: false,
                      barPercentage: "0.65",
                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          beginAtZero: true,
                          ticks: {
                            font: {
                              weight: BOLD,
                            },
                          },
                        },
                        x: {
                          max: 30,
                          ticks: {
                            precision: 0,
                          },
                        },
                      },
                      plugins: {
                        legend: {
                          align: END,
                        },
                      },
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } as any
                  }
                />
              </div>
            ) : (
              t("dashboard:NO_DATA")
            )}
          </div>
        </div>
        <div className="row dashboard-block">
          <div className="col-fit">
            <div className="score-card">
              <div className="card-head">{t("dashboard:TOTAL_ACCOUNTS")}</div>
              <div className="card-body">{totalAccountsCount}</div>
            </div>
          </div>
          <div className="col-fit">
            <div className="score-card">
              <div className="card-head">
                {t("dashboard:INTERNAL_ACCOUNTS")}
              </div>
              <div className="card-body">{internalAccountsCount}</div>
            </div>
          </div>
          <div className="col-fit">
            <div className="score-card">
              <div className="card-head">
                {t("dashboard:EXTERNAL_ACCOUNTS")}
              </div>
              <div className="card-body">{externalAccountsCount}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-2-5">
        <div className="card dashboard-block">
          <div className="card-head">
            <h2>{t("dashboard:CONTRIBUTIONS_PER_CATEGORY")}</h2>
          </div>
          <div className="card-body">
            {ideasPerTypology.length > 0 ? (
              <Doughnut
                data={{
                  labels: ideasPerTypology.map(
                    ([type, val]) =>
                      `${t(`contributions:type.${type}`)} ${val}`,
                  ),
                  datasets: [
                    {
                      data: ideasPerTypology.map(([, val]) => val),
                      backgroundColor: [
                        /* eslint-disable i18next/no-literal-string */
                        "rgb(239, 209, 125)",
                        "rgb(117, 181, 70)",
                        "rgb(97, 198, 220)",
                        "rgb(247, 167, 0)",
                        "rgb(100, 201, 130)",
                        "rgb(167, 216, 233)",
                        /* eslint-enable i18next/no-literal-string */
                      ],
                    },
                  ],
                }}
                options={{
                  cutout: "80%",
                  plugins: {
                    legend: {
                      position: RIGHT,
                      labels: {
                        font: {
                          weight: BOLD,
                        },
                      },
                    },
                  },
                }}
              />
            ) : (
              t("dashboard:NO_DATA")
            )}
          </div>
        </div>
        <div className="card dashboard-block">
          <div className="card-head">
            <h2>{t("dashboard:TOP_CONTRIBUTIONS")}</h2>
            <h3>{t("dashboard:ALL_CATEGORIES")}</h3>
          </div>
          <div className="card-body">
            <div className="tabs">
              <button
                type="button"
                className={cx("btn tab-item", topIdeasInternal && "active")}
                onClick={() => setTopIdeasInternal(true)}
              >
                {t("users:type.INTERNAL")}
              </button>
              <button
                type="button"
                className={cx("btn tab-item", !topIdeasInternal && "active")}
                onClick={() => setTopIdeasInternal(false)}
              >
                {t("users:type.EXTERNAL")}
              </button>
            </div>
            <div className="contributions-list">
              {topIdeas.map((idea) => (
                <div
                  key={`${idea.country}-${idea.id}`}
                  className="contribution-item row"
                >
                  <div className="col-auto">
                    <div className="contribution-title">{idea.project}</div>
                    <div className="contribution-description">
                      {t(
                        `contributions:type.${
                          CONTRIBUTION_TYPES_ENTRIES.find(
                            (e) => Number(e[0]) === idea.type,
                          )![1]
                        }`,
                      )}
                    </div>
                  </div>
                  <div className="col-fit">
                    <div className="contribution-score">
                      {idea.totalCompanyScore}/30
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DashBoard = (props: RouteComponentProps): JSX.Element => {
  const { from, to } = useQueryParams();
  const { t } = useTranslation([
    "dashboard",
    "contributions",
    "committees",
    "users",
  ]);

  const [environments, setEnvironments] = useState([
    Environment.France,
    Environment.Italy,
    Environment.Spain,
    Environment.Plant,
  ]);

  const fromDateDefault = from
    ? parseISO(from as string)
    : addMonths(new Date(), -3);
  const toDateDefault = to ? parseISO(to as string) : new Date();

  const [fromDate, setFromDate] = useState(fromDateDefault);
  const [fromDateDebounced, setFromDateDebounced] = useState(fromDate);
  const [toDate, setToDate] = useState(toDateDefault);
  const [toDateDebounced, setToDateDebounced] = useState(toDate);

  useDeepEffect(
    () => {
      const effect = setTimeout(() => {
        if (!isValid(fromDate) || !isValid(toDate)) return;

        const searchParams = new URLSearchParams(window.location.search);
        // eslint-disable-next-line i18next/no-literal-string
        searchParams.set("from", formatISO(fromDate));
        // eslint-disable-next-line i18next/no-literal-string
        searchParams.set("to", formatISO(toDate));

        setFromDateDebounced(fromDate);
        setToDateDebounced(toDate);
        // Update browser query params
        // eslint-disable-next-line no-restricted-globals
        history.pushState(
          null,
          "",
          window.location.pathname + "?" + searchParams.toString(),
        );
      }, 500);

      return () => {
        clearTimeout(effect);
      };
    },
    [fromDate, toDate],
    false,
  );

  return (
    <div className="dashboard-layout">
      <div className="page-head">
        <div className="grid row">
          <div className="col-md-3-5">
            <div className="dashboard-block">
              <div className="grid" style={{ justifyContent: "right" }}>
                <h1
                  className="page-title col-md-2-6"
                  style={{ justifySelf: "left" }}
                >
                  {t(`dashboard:DASHBOARD`)}
                </h1>
                <label className="label col-md-2-6">
                  {t("dashboard:FROM_DATE")}
                  <input
                    className="input"
                    value={formatISO(fromDate, { representation: "date" })}
                    type="date"
                    onChange={(ev) =>
                      ev.target.value.length > 0
                        ? setFromDate(parseISO(ev.target.value))
                        : setFromDate(fromDateDefault)
                    }
                  />
                </label>
                <label className="label col-md-2-6">
                  {t("dashboard:TO_DATE")}
                  <input
                    className={"input"}
                    value={formatISO(toDate, { representation: "date" })}
                    type="date"
                    onChange={(ev) =>
                      ev.target.value.length > 0
                        ? setToDate(parseISO(ev.target.value))
                        : setFromDate(toDateDefault)
                    }
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-2-5">
            <div className="dashboard-block">
              <label>
                {t("dashboard:COUNTRY")}
                <MultiSelect
                  labelledBy={t("dashboard:COUNTRY")}
                  value={environments.map((e) => ({
                    label: e,
                    value: e,
                  }))}
                  onChange={(ev: Option[]) =>
                    setEnvironments(ev.map((e) => e.value as Environment))
                  }
                  options={Object.values(Environment).map((e) => ({
                    label: e,
                    value: e,
                  }))}
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <DashboardBody
        environments={environments}
        fromDate={fromDateDebounced}
        toDate={toDateDebounced}
      />
    </div>
  );
};

export default withProvideDashboard(DashBoard);
