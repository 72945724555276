import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const PublicFooter: FunctionComponent = () => {
  const { t } = useTranslation(["auth", "ui"]);

  return (
    <div className="auth-footer">
      <a
        href="https://www.knaufinsulation.fr/"
        target="_blank"
        rel="noreferrer"
        className="link discreet-link"
      >
        {t("auth:GROUP_WEBSITE")}
      </a>

      <a
        href="https://www.knaufinsulation.fr/politique-de-confidentialité"
        rel="noreferrer"
        target="_blank"
        className="link discreet-link"
      >
        {t("auth:SECURITY_POLICY")}
      </a>
      <a
        href="https://www.knaufinsulation.fr/politique-en-matière-de-cookies"
        rel="noreferrer"
        target="_blank"
        className="link discreet-link"
      >
        {t("auth:COOKIES_POLICY")}
      </a>
      <div
        className="copyright"
        dangerouslySetInnerHTML={{ __html: t("auth:COPYRIGHT") }}
      />
    </div>
  );
};

export default PublicFooter;
