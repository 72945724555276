import baseAPI from "./baseAPI";
import { AxiosPromise } from "axios";
import {
  ContactForm,
  LoggedUser,
  User,
  UserToRegister,
  UserToSend,
} from "./user";
import {
  Contribution,
  ContributionToSend,
} from "../contributions/contribution";

const LOCAL_STORAGE_USER_KEY = "user";

export function logout(): AxiosPromise<void> {
  return baseAPI.get("/logout");
}

export function login(
  user: UserToSend,
  contribution?: ContributionToSend,
): AxiosPromise<{
  user: LoggedUser;
  message: string;
  contributionId?: Contribution["id"];
}> {
  return baseAPI.post("/login", { ...user, contribution });
}

export function getLocalUser(): LoggedUser {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY) as string);
}

export function setLocalUser(user: LoggedUser): void {
  return localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(user));
}

export function isSetLocalUser(): boolean {
  return localStorage.getItem("user") !== null;
}

export function deleteLocalUser(): void {
  return localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
}

export function lostPassword(email: User["email"]): AxiosPromise<void> {
  return baseAPI.post("/users/reset-pwd", { email });
}

export function resetPassword(): AxiosPromise<void> {
  return baseAPI.post("/users/update-pwd");
}

export function getUserById(id: User["userId"]): AxiosPromise<User> {
  return baseAPI.get("/users/" + id);
}

export function updateUserById(user: User): AxiosPromise<void> {
  return baseAPI.put(`/users/${user.userId}`, user);
}

export function register(user: UserToRegister): AxiosPromise<void> {
  return baseAPI.post("/users", user);
}

export function createUser(user: Partial<User>): AxiosPromise<void> {
  return baseAPI.post("/users", user);
}

export function validateUserRegistration(guid: string): AxiosPromise<void> {
  return baseAPI.get(`/users/validate/${guid}`);
}

export function registerAndCreateContributionWithAuth0(
  user: UserToRegister,
  contribution: ContributionToSend,
  auth0AccessToken: string,
): AxiosPromise<void> {
  return baseAPI.post("/public/contributions", {
    user,
    contribution,
    auth0AccessToken,
  });
}

export function registerAndCreateContribution(
  user: UserToRegister,
  contribution: ContributionToSend,
  gRecaptchaToken: string,
): AxiosPromise<void> {
  return baseAPI.post(
    "/public/contributions",
    { user, contribution },
    { headers: { "grecaptcha-token": gRecaptchaToken } },
  );
}

export function sendContact(contactForm: ContactForm): AxiosPromise<void> {
  return baseAPI.post("/contact", contactForm);
}
