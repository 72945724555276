import { useTranslation } from "react-i18next";
import { USERS_ADMIN_PANEL_LINK } from "../../../routes/admin";
import Link from "../../../services/routing/components/Link";
import { RouteComponentProps, useNavigate } from "@reach/router";
import { Form, Formik } from "formik";
import ValidationsErrors from "../../../services/validations/ValidationsErrors";
import { object, string } from "yup";
import { useMemo } from "react";
import useAuth from "../../../services/auth/hooks/useAuth";
import { useToasts } from "../../../services/toast-notifications";

const NewUser = (props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation(["auth", "ui", "users"]);
  const { createUser } = useAuth();
  const { success, error } = useToasts();
  const navigate = useNavigate();

  const NewUserSchema = useMemo(
    () =>
      object()
        .shape({
          email: string().label(t("auth:EMAIL")).required().email(),
          lastname: string().label(t("auth:LASTNAME")).required(),
          firstname: string().label(t("auth:FIRSTNAME")).required(),
          type: string().label(t("auth:ROLE")),
        })
        .defined(),
    [t],
  );

  return (
    <>
      <div className="page-head">
        <h1 className="page-title">{t("users:USERS_MANAGEMENT")}</h1>
        <Link className="link link-small" to={USERS_ADMIN_PANEL_LINK}>
          &lt; {t("users:GO_BACK_TO_USERS_MANAGEMENT")}
        </Link>
      </div>
      <div className="page-content">
        <Formik
          initialValues={{
            email: "",
            lastname: "",
            firstname: "",
            role: "0",
          }}
          onSubmit={(values) => {
            return createUser({
              email: values.email,
              lastname: values.lastname,
              firstname: values.firstname,
              admin: Number(values.role),
            }).then(
              () => {
                success(t("auth:register.TOAST_SUCCESS"));
                navigate(USERS_ADMIN_PANEL_LINK);
              },
              (err) => {
                if (err.response.status === 409) {
                  error(t("auth:register.MAIL_ALREADY_USED"));
                } else {
                  error(t("auth:register.TOAST_ERROR"));
                }
              },
            );
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={NewUserSchema}
        >
          {({ values, handleChange }) => (
            <Form autoComplete="off" className="auth-form" noValidate>
              <div className="input-block">
                <label className="input-label" htmlFor="email">
                  {t("auth:EMAIL")} *
                </label>
                <input
                  name="email"
                  className="input"
                  onChange={handleChange}
                  placeholder={t("auth:EMAIL_PLACEHOLDER")}
                  type="email"
                />
              </div>
              <div className="input-block grid">
                <div className="col-md-1-2">
                  <label className="input-label" htmlFor="lastname">
                    {t("auth:LASTNAME")} *
                  </label>
                  <input
                    name="lastname"
                    className="input"
                    onChange={handleChange}
                    placeholder={t("auth:LASTNAME_PLACEHOLDER")}
                  />
                </div>
                <div className="col-md-1-2">
                  <label className="input-label" htmlFor="firstname">
                    {t("auth:FIRSTNAME")} *
                  </label>
                  <input
                    name="firstname"
                    className="input"
                    onChange={handleChange}
                    placeholder={t("auth:FIRSTNAME_PLACEHOLDER")}
                    type="email"
                  />
                </div>
              </div>
              <div className="input-block">
                <label className="input-label" htmlFor="role">
                  {t("auth:ROLE")}
                </label>
                <select
                  name="role"
                  className="input"
                  defaultValue={values.role}
                  onChange={handleChange}
                >
                  <option value="0"></option>
                  <option value="1">{t("auth:ADMIN")}</option>
                </select>
              </div>

              <ValidationsErrors />

              <div className={"btns-bar form-footer"}>
                <button className="btn-1" type="submit">
                  {t("ui:SAVE")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default NewUser;
