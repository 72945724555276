export function upsertObjectInNewArray<T>(
  array: T[],
  elementToUpsert: T,
  isElement: (e: T) => boolean,
): T[] {
  const newArray = [...array];
  const elementIdx = newArray.findIndex(isElement);

  if (elementIdx >= 0) {
    newArray[elementIdx] = { ...newArray[elementIdx], ...elementToUpsert };
  } else {
    newArray.push(elementToUpsert);
  }

  return newArray;
}

export function multiSorts<T>(...sorts: ((a: T, b: T) => number)[]) {
  return (a: T, b: T): number =>
    sorts.reduce((result, sort) => result || sort(a, b), 0);
}
