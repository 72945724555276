import { PRIVATE } from "./private";

export const ADMIN = `${PRIVATE}/admin` as const;
export const ADMIN_PATH = `/admin/*` as const;

export const USERS_ADMIN_PANEL = "/users" as const;
export const USERS_ADMIN_PANEL_LINK = `${ADMIN}${USERS_ADMIN_PANEL}` as const;

export const USERS_ADMIN_NEW_USER = "/users/new" as const;
export const USERS_ADMIN_NEW_USER_LINK = `${ADMIN}${USERS_ADMIN_NEW_USER}` as const;
