/* eslint-disable i18next/no-literal-string */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languageDetectorI18next from "i18next-browser-languagedetector";
import AUTH_FR from "../auth/i18n/fr.json";
import AUTH_EN from "../auth/i18n/en.json";
import CONTRIBUTIONS_FR from "../contributions/i18n/fr.json";
import VALIDATIONS_FR from "../validations/i18n/fr.json";
import USERS_FR from "../users/i18n/fr.json";
import COMMITTEES_FR from "../committees/i18n/fr.json";
import UI_FR from "../ui/i18n/fr.json";
import ICONS_FR from "../icons/i18n/fr.json";
import CONTRIBUTIONS_EN from "../contributions/i18n/en.json";
import VALIDATIONS_EN from "../validations/i18n/en.json";
import USERS_EN from "../users/i18n/en.json";
import COMMITTEES_EN from "../committees/i18n/en.json";
import UI_EN from "../ui/i18n/en.json";
import ICONS_EN from "../icons/i18n/en.json";
import DASHBOARD_FR from "../dashboard/i18n/fr.json";
import DASHBOARD_EN from "../dashboard/i18n/en.json";
import { setLocale } from "yup";

i18n
  .use(languageDetectorI18next)
  .use(initReactI18next)
  .init({
    ns: ["auth", "contributions", "validations", "users", "committees"],
    resources: {
      fr: {
        auth: AUTH_FR,
        contributions: CONTRIBUTIONS_FR,
        validations: VALIDATIONS_FR,
        users: USERS_FR,
        committees: COMMITTEES_FR,
        ui: UI_FR,
        icons: ICONS_FR,
        dashboard: DASHBOARD_FR,
      },
      en: {
        auth: AUTH_EN,
        contributions: CONTRIBUTIONS_EN,
        validations: VALIDATIONS_EN,
        users: USERS_EN,
        committees: COMMITTEES_EN,
        ui: UI_EN,
        icons: ICONS_EN,
        dashboard: DASHBOARD_EN,
      },
    },
    detection: {
      order: ["localStorage"],
      caches: ["localStorage"],
    },
    fallbackLng: "fr",

    interpolation: {
      escapeValue: false,
    },
  });

export interface ValidationError {
  key: string;
  values: Record<string, unknown>;
}

function getError(key: string) {
  return (values: unknown) => ({ key, values } as ValidationError);
}

setLocale({
  mixed: {
    default: getError("invalid_field"),
    required: getError("required"),
    defined: getError("defined"),
    oneOf: getError("one_of"),
  },
  string: {
    email: getError("email"),
  },
});

export default i18n;
