import { DependencyList, EffectCallback, useEffect, useRef } from "react";

export function deepEqual<
  T extends boolean | null | undefined | number | string | Date,
  Rec extends Record<string | number | symbol, Supported>,
  Supported extends T | Array<T> | Rec
>(a: Supported, b: Supported): boolean {
  // Handle different types
  if (typeof a !== typeof b) return false;

  // Handle boolean, null, undefined, number, string
  if (a === b) return true;
  if (a === null || b === null) return false;
  if (a === undefined || b === undefined) return false;

  // Handle Date
  if (a instanceof Date && b instanceof Date) {
    if (a.getTime() === b.getTime()) return true;
    else return false;
  }

  // Handle Array and Record
  if (typeof a === "object" && typeof b === "object") {
    // Handle Array
    if (Array.isArray(a) && Array.isArray(b)) {
      if (a.length !== b.length) return false;
      return a.every((item, index) => deepEqual(item, b[index]));
    } else if (Array.isArray(a) || Array.isArray(b)) return false;

    // Handle Record
    const aRec = a as Rec;
    const bRec = b as Rec;
    const aKeys = Object.keys(aRec);
    const bKeys = Object.keys(bRec);
    if (aKeys.length !== bKeys.length) return false;
    // Check if b has the same keys as a
    if (aKeys.some((key) => !bKeys.includes(key))) return false;
    // Compare values
    return aKeys.every((key) => deepEqual(aRec[key], bRec[key]));
  }
  return false;
}

export function useDeepEffect(
  effect: EffectCallback,
  dependencies: DependencyList,
  runInit = true,
): void {
  const inited = useRef<boolean>(false);
  const ref = useRef<DependencyList | null>(null);

  useEffect(() => {
    let destructor;

    if (!deepEqual(dependencies, ref.current)) {
      const needToRun = inited.current || runInit;
      ref.current = dependencies;

      if (!inited.current) inited.current = true;

      if (needToRun) destructor = effect();
    }

    return destructor;
  }, [dependencies, effect, runInit]);
}
