import { cx } from "@emotion/css";
import { RouteComponentProps } from "@reach/router";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import LangSwitch from "src/services/i18n/LangSwitch";
import NavLink from "src/services/routing/components/NavLink";
import PublicFooter from "src/services/ui/elements/PublicFooter";
import knaufLogo from "../assets/img/logo-white-baseline.png";
import IconMenu from "src/services/icons/IconMenu";
import { useAuth0 } from "@auth0/auth0-react";
import { LOGOUT } from "src/routes/public";

const AccountNotAuthorized = (props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation(["auth"]);
  const { logout: auth0Logout } = useAuth0();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  const logout = useCallback(
    () =>
      auth0Logout({
        returnTo: (process.env.REACT_APP_FRONT_HOST || "") + LOGOUT,
      }),
    [auth0Logout],
  );

  return (
    <div className={"v-layout"}>
      <div className="auth-layout">
        <aside className={cx(["sidebar", isSidebarExpanded && " expanded"])}>
          <LangSwitch />
          <nav className="sidebar-nav">
            <NavLink to={"/"}>
              <img src={knaufLogo} alt="" className="logo" />
            </NavLink>
            <button
              className="btn-icon btn-icon-m btn-burger"
              onClick={() => setIsSidebarExpanded(!isSidebarExpanded)}
            >
              <IconMenu />
            </button>
          </nav>
        </aside>
        <main className="content">
          <div className="container page-content">
            <h1 className="page-title">{t("auth:403_UNAUTHORIZED")}</h1>
            <h4>{t("auth:USER_NOT_AUTHORIZED")}</h4>
            <div className="btns-bar vertical">
              <button
                onClick={() => logout()}
                type="button"
                className="btn btn-1"
              >
                {t("auth:GO_BACK_TO_LOGIN")}
              </button>
            </div>
          </div>
        </main>
      </div>
      <PublicFooter />
    </div>
  );
};

export default AccountNotAuthorized;
