import { Redirect, RouteComponentProps, Router } from "@reach/router";
import React, { FunctionComponent } from "react";
import UsersAdminPanel from "./UsersAdminPanel";
import {
  ADMIN,
  USERS_ADMIN_NEW_USER,
  USERS_ADMIN_PANEL,
} from "../../../routes/admin";
import { PRIVATE } from "../../../routes/private";
import CommitteePanel from "./committees/CommitteePanel";
import { COMMITTEES_PANEL_PATH } from "../../../routes/committees";
import NewUser from "./NewUser";

const Admin: FunctionComponent<RouteComponentProps> = () => {
  return (
    <Router>
      <Redirect from={ADMIN} to={PRIVATE} noThrow default />
      <UsersAdminPanel path={USERS_ADMIN_PANEL} />
      <CommitteePanel path={COMMITTEES_PANEL_PATH} />
      <NewUser path={USERS_ADMIN_NEW_USER} />
    </Router>
  );
};

export default Admin;
