import {
  Contribution,
  ContributionBase,
  ContributionStatus,
  ContributionType,
} from "../contributions/contribution";

export enum Environment {
  France = "france",
  Italy = "italy",
  Spain = "spain",
  Plant = "plant",
}

export const EnvColor = {
  // eslint-disable-next-line i18next/no-literal-string
  [Environment.France]: "rgba(97, 198, 220, 0.75)",
  // eslint-disable-next-line i18next/no-literal-string
  [Environment.Italy]: "rgba(117, 181, 70, 0.75)",
  // eslint-disable-next-line i18next/no-literal-string
  [Environment.Spain]: "rgba(247, 167, 0, 0.75)",
  // eslint-disable-next-line i18next/no-literal-string
  [Environment.Plant]: "rgba(239, 209, 125, 0.75)",
};

export interface AllEnvironmentsStats {
  france?: StatsByEnvironment;
  spain?: StatsByEnvironment;
  italy?: StatsByEnvironment;
  plant?: StatsByEnvironment;
}

export interface StatsByEnvironment {
  contributionsCount: number;
  allUsersCount: number;
  internalUsersCount: number;
  externalUsersCount: number;
  topContributions: (Contribution & {
    customerScore1: number;
    customerScore2: number;
    customerScore3: number;
    companyScore1: number;
    companyScore2: number;
    companyScore3: number;
    totalCustomerScore: number;
    totalCompanyScore: number;
    internal: boolean;
    Histories: NonNullable<ContributionBase["Histories"]>;
  })[];
  countByType: {
    count: number;
    type: ContributionType;
  }[];
  countByStatus: Record<number, ContributionStatus>;
  averageCustomerScore: number;
  averageCompanyScore: number;
}
