import {
  ComponentType,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { getDashboard } from "./api";
import { AllEnvironmentsStats } from "./dashboard";

interface DashboardAPI {
  dashboard: AllEnvironmentsStats | null;
  loadDashboard(
    environments: string[],
    fromDate: Date,
    toDate: Date,
  ): Promise<void>;
}

export const DashboardContext = createContext<DashboardAPI | null>(null);

export const ProvideDashboard = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [dashboard, setDashboard] = useState<DashboardAPI["dashboard"]>(null);
  const loadDashboard: DashboardAPI["loadDashboard"] = useCallback(
    (environments, fromDate, toDate) =>
      getDashboard({ from: fromDate, to: toDate }, environments).then(
        ({ data }) => {
          setDashboard(data);
        },
      ),
    [],
  );

  return (
    <DashboardContext.Provider
      value={{
        dashboard,
        loadDashboard,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export function withProvideDashboard<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    // eslint-disable-next-line i18next/no-literal-string
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideDashboard(props: P) {
    return (
      <ProvideDashboard>
        <WrappedComponent {...props} />
      </ProvideDashboard>
    );
  }

  WithProvideDashboard.displayName = `withProvideDashboard(${displayName})`;

  return WithProvideDashboard;
}

export function useDashboard(): DashboardAPI {
  return useContext(DashboardContext) as DashboardAPI;
}
