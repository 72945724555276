import { AuthAPI } from "../types";
import { useEffect, useMemo, useState } from "react";
import {
  deleteLocalUser,
  getLocalUser,
  getUserById,
  isSetLocalUser,
  login,
  logout,
  lostPassword,
  register,
  createUser,
  setLocalUser,
  validateUserRegistration,
  updateUserById,
  resetPassword,
  registerAndCreateContribution,
  registerAndCreateContributionWithAuth0,
} from "../api";
import baseAPI from "../baseAPI";
import { LoggedUser, UserToSend } from "../user";
import { ContributionToSend } from "../../contributions/contribution";

const defaultUser = isSetLocalUser() ? getLocalUser() : null;

export function useProvideAuth(): AuthAPI {
  const [user, setUser] = useState<LoggedUser | null>(defaultUser);
  const [auth0State, setAuth0State] = useState<AuthAPI["auth0State"]>(null);

  useMemo(() => {
    if (user !== null) {
      setLocalUser(user);
      baseAPI.defaults.headers["Authorization"] = `Bearer ${user.xsrfToken}`;
    } else {
      deleteLocalUser();
      delete baseAPI.defaults.headers["Authorization"];
    }
  }, [user]);

  useEffect(() => {
    const interceptor = baseAPI.interceptors.response.use(
      (res) => res,
      (error) => {
        if (error?.response?.status === 401) {
          setUser(null);
        }
        return Promise.reject(error);
      },
    );

    return () => {
      baseAPI.interceptors.response.eject(interceptor);
    };
  }, []);

  return {
    user,
    auth0State,
    setAuth0State,
    login(u: UserToSend, c?: ContributionToSend) {
      return login(u, c).then((res) => {
        setUser(res.data.user);
        return { user: res.data.user, contributionId: res.data.contributionId };
      });
    },
    logout(): Promise<void> {
      setUser(null);
      return logout().then(() => {
        return Promise.resolve();
      });
    },
    checkUserValidity(): Promise<void> {
      if (isSetLocalUser()) {
        const u = getLocalUser() as LoggedUser;
        return getUserById(u.userId).then(
          (res) => {
            const newUser = { ...u, ...res.data };
            setUser(newUser);
            setLocalUser(newUser);
          },
          (err) => {
            if (err?.response?.status === 401) {
              setUser(null);
              deleteLocalUser();
            }
          },
        );
      }
      return Promise.resolve();
    },
    register(user) {
      return register(user);
    },
    validateUserRegistration(guid) {
      return validateUserRegistration(guid);
    },
    updateUser(newUser) {
      return updateUserById(newUser).then(() => {
        setUser((prevUser) =>
          prevUser
            ? {
                ...prevUser,
                ...newUser,
              }
            : null,
        );
      });
    },
    lostPassword(email: string) {
      return lostPassword(email);
    },
    resetPassword() {
      return resetPassword();
    },
    registerAndCreateContributionWithCaptcha(
      user,
      contribution,
      gRecaptchaToken,
    ) {
      return registerAndCreateContribution(user, contribution, gRecaptchaToken);
    },
    registerAndCreateContributionWithAuth0(
      user,
      contribution,
      auth0AccessToken,
    ) {
      return registerAndCreateContributionWithAuth0(
        user,
        contribution,
        auth0AccessToken,
      );
    },
    createUser(user) {
      return createUser(user);
    },
  };
}
