import React, { FunctionComponent, useMemo, useState } from "react";
import { useProvideContributions } from "../../services/contributions/useProvideContributions";
import { RouteComponentProps } from "@reach/router";
import useLoader from "../../services/routing/useLoader";
import Loading from "../../services/routing/components/Loading";
import LoaderErrors from "../../services/routing/components/LoaderErrors";
import {
  CONTRIBUTION_ALL_STATUSES,
  CONTRIBUTION_COUNTRIES_ENTRIES,
  CONTRIBUTION_STATUSES_ENTRIES,
  ContributionStatus,
  getCurrentStatus,
  getTotalScoreSum,
} from "../../services/contributions/contribution";
import { useTranslation } from "react-i18next";
import ContributionCard from "../../services/contributions/ContributionCard";
import { compareAsc, compareDesc, parseISO } from "date-fns";
import { searchGenerator } from "../../services/filter/search";
import { useProvideCommittees } from "src/services/committees/useProvideCommittees";
import { multiSorts } from "../../services/data-structures/array";
import { isInternal } from "../../services/auth/user";

const PAGE_SIZE = 12;

const Contributions: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation(["contributions", "ui", "auth"]);
  /* Loader */
  const { contributions, loadAllContributions } = useProvideContributions();
  const { loadAllCommittees } = useProvideCommittees();

  const { loading, error, reload } = useLoader(
    () => Promise.all([loadAllContributions(), loadAllCommittees()]),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  /* Order */
  const [orderDateAsc, setOrderDateAsc] = useState<boolean>(false);
  const [orderScoreAsc, setOrderScoreAsc] = useState<boolean>(false);

  /* Filters */
  const [filteredStatus, setFilteredStatus] = useState<
    ContributionStatus | typeof CONTRIBUTION_ALL_STATUSES
  >(CONTRIBUTION_ALL_STATUSES);
  const [filteredCountry, setFilteredCountry] = useState<string>("");
  const [
    filteredInternalExternal,
    setFilteredInternalExternal,
  ] = useState<string>("");
  const [searchedText, setSearchedText] = useState("");

  /* Size */
  const [listSize, setListSize] = useState(PAGE_SIZE);

  const filteredContributions = useMemo(() => {
    let filteredContributions = [...contributions.values()];

    if (filteredCountry !== "")
      filteredContributions = filteredContributions.filter(
        (c) => c.country === filteredCountry,
      );

    if (filteredInternalExternal !== "")
      filteredContributions = filteredContributions.filter((c) =>
        filteredInternalExternal === "internal"
          ? isInternal(c.User)
          : !isInternal(c.User),
      );

    if (filteredStatus !== CONTRIBUTION_ALL_STATUSES)
      filteredContributions = filteredContributions.filter(
        (c) => getCurrentStatus(c) === filteredStatus,
      );

    filteredContributions = filteredContributions.filter(
      searchGenerator(searchedText),
    );

    filteredContributions.sort(
      multiSorts(
        orderScoreAsc
          ? (a, b) => {
              const aScore = getTotalScoreSum(a);
              const bScore = getTotalScoreSum(b);
              return aScore === null && bScore === null
                ? 0
                : aScore === null
                ? 1
                : bScore === null
                ? -1
                : aScore - bScore;
            }
          : (a, b) => (getTotalScoreSum(b) || 0) - (getTotalScoreSum(a) || 0),
        (a, b) =>
          (orderDateAsc ? compareAsc : compareDesc)(
            parseISO(a.createdAt),
            parseISO(b.createdAt),
          ),
      ),
    );

    return filteredContributions;
  }, [
    contributions,
    filteredCountry,
    filteredInternalExternal,
    filteredStatus,
    searchedText,
    orderScoreAsc,
    orderDateAsc,
  ]);

  if (loading) return <Loading />;
  if (error) return <LoaderErrors reload={reload} error={error} />;

  return (
    <>
      <div className="page-head">
        <h1 className="page-title">{t(`contributions:ALL_CONTRIBUTIONS`)}</h1>
      </div>

      <div className="grid">
        {contributions.size > 0 && (
          <div className="col-md-1-2">
            <input
              type="text"
              className="input input-search"
              placeholder={t("contributions:SEARCH_CONTRIBUTION")}
              onChange={(ev) => setSearchedText(ev.target.value)}
            />
          </div>
        )}
      </div>

      {contributions.size > 0 && (
        <div className="grid">
          <div className="col-md-1-2 col-lg-1-4">
            <label htmlFor="" className="input-label">
              {t("contributions:filter.SORT_BY")}
            </label>
            <select
              className="select"
              onChange={(ev) => {
                setOrderDateAsc(ev.target.value === "+");
              }}
              defaultValue={`${orderScoreAsc ? "+" : "-"}`}
            >
              <option value="+">{t("ui:ASCENDANT_DATE")}</option>
              <option value="-">{t("ui:DESCENDANT_DATE")}</option>
            </select>
          </div>
          <div className="col-md-1-2 col-lg-1-4">
            <label htmlFor="" className="input-label">
              {t("contributions:filter.SORT_BY")}
            </label>
            <select
              className="select"
              onChange={(ev) => {
                setOrderScoreAsc(ev.target.value === "+");
              }}
              defaultValue={`${orderScoreAsc ? "+" : "-"}`}
            >
              <option value="+">
                {t("contributions:filter.ASCENDANT_SCORE")}
              </option>
              <option value="-">
                {t("contributions:filter.DESCENDANT_SCORE")}
              </option>
            </select>
          </div>
          <div className="col-md-1-2 col-lg-1-4">
            <label className="input-label">
              {t("contributions:filter.FILTER_BY_COUNTRY")}
            </label>
            <select
              className="select"
              onChange={(ev) => setFilteredCountry(ev.target.value)}
              defaultValue={filteredStatus}
            >
              <option value={""}>{t("contributions:country.ALL")}</option>
              {CONTRIBUTION_COUNTRIES_ENTRIES.map(([id, name]) => (
                <option value={id} key={id}>
                  {t(`contributions:country.${name}`)}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-1-2 col-lg-1-4">
            <label className="input-label">
              {t("contributions:filter.FILTER_BY_STATUS")}
            </label>
            <select
              className="select"
              onChange={(ev) =>
                setFilteredStatus(parseInt(ev.target.value, 10))
              }
              defaultValue={filteredStatus}
            >
              <option value={CONTRIBUTION_ALL_STATUSES}>
                {t("contributions:status.ALL")}
              </option>
              {CONTRIBUTION_STATUSES_ENTRIES.slice(1).map(([id, name]) => (
                <option value={id} key={id}>
                  {t(`contributions:status.${name}`)}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-1-2 col-lg-1-4">
            <label className="input-label">
              {t("contributions:filter.FILTER_BY_INTERNAL_EXTERNAL")}
            </label>
            <select
              className="select"
              onChange={(ev) => setFilteredInternalExternal(ev.target.value)}
              defaultValue={filteredStatus}
            >
              <option value={""}>{t("contributions:status.ALL")}</option>
              <option value={"internal"}>
                {t("contributions:internal_external.INTERNAL")}
              </option>
              <option value={"external"}>
                {t("contributions:internal_external.EXTERNAL")}
              </option>
            </select>
          </div>
        </div>
      )}

      <div className="grid contributions-list">
        {filteredContributions.slice(0, listSize).map((c) => (
          <div key={c.id} className="col-md-1-2 col-lg-1-3">
            <ContributionCard contribution={c} />
          </div>
        ))}
        {filteredContributions.length > listSize && (
          <div className="col-1-1 load-more">
            <button
              className="link"
              onClick={() => setListSize((s) => s + PAGE_SIZE)}
            >
              {t("ui:SEE_MORE")}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Contributions;
