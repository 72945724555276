import React, { FunctionComponent, useCallback, useEffect } from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import Profile from "./Profile";
import {
  CONTRIBUTION_DETAILS,
  CONTRIBUTIONS,
  CONTRIBUTIONS_LINK,
  DASHBOARD,
  DASHBOARD_LINK,
  NEW_CONTRIBUTION,
  PRIVATE,
  PRIVATE_CONTACT,
  PRIVATE_COOKIE_POLICY,
  PRIVATE_TERMS_OF_USE,
  PROFILE,
  PROFILE_LINK,
} from "../../routes/private";
import { getShortName, isAdmin } from "../../services/auth/user";
import useAuth from "../../services/auth/hooks/useAuth";
import { AuthAPIConnected } from "../../services/auth/types";
import NavLink from "../../services/routing/components/NavLink";
import Link from "../../services/routing/components/Link";
import Contributions from "./Contributions";
import ContributionDetails from "./ContributionDetails";
import NewContribution from "./NewContribution";
import { LOGOUT_BUTTON_ID } from "../../fixtures/auth";
import Admin from "./admin/Admin";
import { ADMIN_PATH, USERS_ADMIN_PANEL_LINK } from "../../routes/admin";
import PrivateFooter from "../../services/ui/elements/PrivateFooter";
import CookiePolicy from "../CookiePolicy";
import TermsOfUse from "../TermsOfUse";
import Contact from "../Contact";
import LangSwitch from "../../services/i18n/LangSwitch";
import { useTranslation } from "react-i18next";
import IconLogo from "../../services/icons/IconLogo";
import IconUser from "../../services/icons/IconUser";
import IconLogout from "../../services/icons/IconLogout";
import { useAuth0 } from "@auth0/auth0-react";
import { LOGOUT } from "../../routes/public";
import DashBoard from "./Dashboard";

const HOME = "/";

const Private: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation([
    "auth",
    "users",
    "committees",
    "contributions",
    "dashboard",
  ]);
  const { user } = useAuth() as AuthAPIConnected;
  const { logout: auth0Logout } = useAuth0();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    // eslint-disable-next-line i18next/no-literal-string
    searchParams.delete("code");
    // eslint-disable-next-line i18next/no-literal-string
    searchParams.delete("state");
    // Update browser query params
    // eslint-disable-next-line no-restricted-globals
    history.pushState(
      null,
      "",
      window.location.pathname + "?" + searchParams.toString(),
    );
  }, []);

  const logout = useCallback(
    () =>
      auth0Logout({
        returnTo: (process.env.REACT_APP_FRONT_HOST || "") + LOGOUT,
      }),
    [auth0Logout],
  );

  /* Getters */
  const isCurrentUserAdmin = isAdmin(user);

  return (
    <div className="app-layout">
      <header className="header">
        <div className="container">
          <Link to={PRIVATE}>
            <IconLogo />
          </Link>
          <nav className="header-nav">
            <NavLink className="nav-item" to={DASHBOARD_LINK}>
              {t("dashboard:DASHBOARD")}
            </NavLink>
            <NavLink className="nav-item" to={CONTRIBUTIONS_LINK}>
              {t("contributions:CONTRIBUTIONS")}
            </NavLink>
            {isCurrentUserAdmin && (
              <NavLink className="nav-item" to={USERS_ADMIN_PANEL_LINK}>
                {t("users:USERS")}
              </NavLink>
            )}
            <NavLink className="nav-item" to={PROFILE_LINK}>
              <IconUser />
              <span>{getShortName(user, t("auth:OWN_ACCOUNT"))}</span>
            </NavLink>
            <button
              type={"button"}
              className="nav-item"
              onClick={logout}
              data-testid={LOGOUT_BUTTON_ID}
            >
              <IconLogout />
              <span>{t("auth:LOGOUT")}</span>
            </button>
            <LangSwitch />
          </nav>
        </div>
      </header>

      <main className="content">
        <div className={"container page-content"}>
          <Router>
            <Redirect from={HOME} to={DASHBOARD_LINK} noThrow default />
            <Profile path={PROFILE} />
            <Contributions path={CONTRIBUTIONS} />
            <ContributionDetails path={CONTRIBUTION_DETAILS} />
            <NewContribution path={NEW_CONTRIBUTION} />
            {isCurrentUserAdmin && <Admin path={ADMIN_PATH} />}
            <CookiePolicy path={PRIVATE_COOKIE_POLICY} />
            <TermsOfUse path={PRIVATE_TERMS_OF_USE} />
            <Contact path={PRIVATE_CONTACT} />
            <DashBoard path={DASHBOARD} />
          </Router>
        </div>
      </main>

      <PrivateFooter />
    </div>
  );
};

export default Private;
