import { formatISO } from "date-fns";
import baseAPI from "../auth/baseAPI";
import { AxiosPromise } from "axios";
import { AllEnvironmentsStats } from "./dashboard";

export function getDashboard(
  { from, to }: { from: Date; to: Date },
  applications: string[],
): AxiosPromise<AllEnvironmentsStats> {
  return baseAPI.get(
    `/dashboard/${encodeURIComponent(
      applications.join(","),
    )}?fromDate=${encodeURIComponent(
      formatISO(from),
    )}&toDate=${encodeURIComponent(formatISO(to))} `,
  );
}
