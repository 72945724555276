import React, { FunctionComponent, useMemo, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import Link from "../../../services/routing/components/Link";
import { CONTRIBUTIONS_LINK } from "../../../routes/private";
import useLoader from "../../../services/routing/useLoader";
import Loading from "../../../services/routing/components/Loading";
import LoaderErrors from "../../../services/routing/components/LoaderErrors";
import { useProvideUsers } from "../../../services/users/useProvideUsers";
import { User, UserRole } from "../../../services/auth/user";
import { useTranslation } from "react-i18next";
import { searchGenerator } from "../../../services/filter/search";
import { useToasts } from "../../../services/toast-notifications";
import useAuth from "../../../services/auth/hooks/useAuth";
import { AuthAPIConnected } from "src/services/auth/types";
import { USERS_ADMIN_NEW_USER_LINK } from "../../../routes/admin";

const UsersAdminPanel: FunctionComponent<RouteComponentProps> = () => {
  /* Loading */
  const { users, loadAllUsers, updateUserRole } = useProvideUsers();
  const { loading, error: loadingError, reload } = useLoader(loadAllUsers, []); // eslint-disable-line react-hooks/exhaustive-deps

  /* APIs */
  const { t } = useTranslation(["users", "auth"]);
  const { success, error } = useToasts();
  const { user: currentUser } = useAuth() as AuthAPIConnected;

  /* Hooks */
  const [searchedText, setSearchedText] = useState("");

  const filteredUsers = useMemo(() => {
    let filteredUsers = [...users.values()];

    filteredUsers = filteredUsers.filter(
      searchGenerator(searchedText, (user) => [
        user.email,
        user.firstname,
        user.lastname,
        user.pseudo,
      ]),
    );

    return filteredUsers;
  }, [users, searchedText]);

  /* Methods */
  const setUserRole = (user: User, newRole: UserRole) => {
    updateUserRole(user.userId, newRole).then(
      () => success(t("users:update-user-role.SUCCESS")),
      () => error(t("users:update-user-role.ERROR")),
    );
  };

  /* End loading */
  if (loading) return <Loading />;
  if (loadingError)
    return <LoaderErrors reload={reload} error={loadingError} />;

  return (
    <>
      <div className="page-head">
        <h1 className="page-title">{t("users:USERS_MANAGEMENT")}</h1>
        <Link className="link link-small" to={CONTRIBUTIONS_LINK}>
          &lt; {t("users:GO_BACK_TO_CONTRIBUTIONS")}
        </Link>
      </div>
      <div className="page-content">
        <div className="grid">
          {users.size > 0 && (
            <div className="col-md-1-2">
              <input
                type="text"
                className="input input-search"
                placeholder={t("users:SEARCH_USER")}
                onChange={(ev) => setSearchedText(ev.target.value)}
              />
            </div>
          )}
          <div className="col-md-1-2">
            <Link className="btn-1" to={USERS_ADMIN_NEW_USER_LINK}>
              {t("auth:NEW_USER")}
            </Link>
          </div>
        </div>
        <div className="grid input-block">
          <table className="table-1 col-1-1">
            <thead>
              <tr>
                <th>{t("auth:EMAIL")}</th>
                <th>{t("auth:LASTNAME")}</th>
                <th>{t("auth:FIRSTNAME")}</th>
                <th>{t("auth:ADMIN")}</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.userId}>
                  <td>{user.email}</td>
                  <td>{user.lastname}</td>
                  <td>{user.firstname}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={user.admin === 1}
                      onChange={(ev) =>
                        setUserRole(user, ev.target.checked ? 1 : 0)
                      }
                      disabled={user.userId === currentUser.userId}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default UsersAdminPanel;
